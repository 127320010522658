<template>
    <div>
        <template v-if="total">
            <template v-if="type === 'range'">
                {{ $t('filters.items_range', [range.start, range.end]) }}
            </template>

            <template v-if="type === 'total'">
                {{ $t('filters.items_total') }}
            </template>

            {{ $t(t, total) }}
        </template>
    </div>
</template>

<script lang="ts" setup>
import type { ApiResponse } from '@composable-api/api.response'

const {
    pagination,
    t,
    type = 'range',
} = defineProps<{
    pagination: InstanceType<typeof ProductFiltersPaginationModel> | ReturnType<ApiResponse<any>['getMeta']> | null | undefined
    t: string
    type?: 'range' | 'total',
}>()

const { range, total } = usePaginationInfo(() => pagination)
</script>
