<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg viewBox="0 0 10 13" xmlns="http://www.w3.org/2000/svg">
        <line x1="0.5" y1="2.18557e-08" x2="0.499999" y2="13" stroke="currentColor"  />
        <g>
            <path d="M10 12L10 10L6 6.001L10 2L10 0L4 6.001L5 7.001L10 12Z" fill="currentColor" />
        </g>
    </svg>
)
</script>
