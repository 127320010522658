<script lang="tsx">
import { defineComponentBaseUiPagination } from '@core/app/components/base/ui/BaseUiPagination.vue'
import { IconChevron, IconChevronAndLine } from '#components'

export default defineComponentBaseUiPagination({
    props: {
        alignEnd: {
            default: false,
        },
        siblingCount: {
            default: 0,
        },
    },
    slots: {
        firstPageButton: () => <IconChevronAndLine size="xs" />,
        previousPageButton: () => <IconChevron size="xs" rotate={90} />,
        nextPageButton: () => <IconChevron size="xs" rotate={-90} />,
        lastPageButton: () => <IconChevronAndLine size="xs" rotate={180} />,
    },
})

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiPagination" as *;

@include wrapper {
    gap: 0;
}

@include button {
    color: $vut-c-secondary;
    border: hr-line();
    border-left: none;
    border-radius: $vut-border-radius;

    background: $vut-c-white;

    width: 3.125rem;
    height: 3.125rem;

    transition: background $vut-trans-time $vut-timing, color $vut-trans-time $vut-timing;
}

@include button--first {
    border-left: hr-line();
}

@include button--hover {
    background: $vut-c-ivory;
    color: $vut-c-black;
}

@include button--active {
    color: $vut-c-gray;

    cursor: not-allowed;
}


@include button--disabled {
    // overriding default styles
    opacity: 1;
}


</style>
